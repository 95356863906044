import * as React from 'react';
import Box from '@mui/material/Box';
import StepIcon from '@mui/material/StepIcon';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {Link} from "react-router-dom";

import firebaseConfig from '../../firebaseConfig';

export default function VerticalLinearStepper({pickupDate, pickupTime,parcelId, showLogin, addUserToDb,noThanks}) {

const {googleBucket} = firebaseConfig;
  return (
    <Box>
        <Typography sx={{paddingLeft:'8px',paddingBottom:'16px',paddingTop:'16px',fontWeight:600}}>What happens next?</Typography>
        <span>
            <div style={{display:'flex', flexDirection:'row'}}>
                <StepIcon icon={2} sx={{color:'secondary.main'}}/>
                <Typography sx={{paddingLeft:'8px',fontSize:'0.875rem',fontWeight:600}}>Your payment receipt</Typography>
            </div>
            <Typography style={{marginLeft:'12px', paddingLeft:'20px', paddingBottom:'16px', borderLeft: '1px solid #bdbdbd'}}>
                Your receipt has been emailed to you. You can also download the receipt 
                <Link
                    to={{pathname:`${googleBucket}/parcels/receipt/${parcelId}.pdf`}}
                    target="_blank"
                    download
                    style={{marginLeft:2}}
                >here.
                </Link>
            </Typography>
        </span>
        <span>
            <div style={{display:'flex', flexDirection:'row'}}>
                <StepIcon icon={2} sx={{color:'secondary.main'}}/>
                <Typography sx={{paddingLeft:'8px',fontSize:'0.875rem',fontWeight:600}}>Your parcel label</Typography>
            </div>
            <Typography style={{marginLeft:'12px', paddingLeft:'20px', paddingBottom:'16px', borderLeft: '1px solid #bdbdbd'}}>
                You will shortly receive an email containing a shipping label. Please download, print, and affix this label to your parcel. In some instances, the courier will provide a pre-printed label. This will be indicated in the email.
            </Typography>
        </span>
        <span>
            <div style={{display:'flex', flexDirection:'row'}}>
                <StepIcon icon={3} sx={{color:'secondary.main'}}/>
                <Typography sx={{paddingLeft:'8px',fontSize:'0.875rem',fontWeight:600}}>Collection by the courier</Typography>
            </div>
            <Typography style={{marginLeft:'12px', paddingLeft:'20px', paddingBottom:'16px', borderLeft: '1px solid #bdbdbd'}}>
                Your parcel will be collected by a Zkootie personnel on <strong>{pickupDate}</strong> between <strong>{pickupTime}</strong>. Please ensure that the parcel is appropriately packed so that it can be collected quickly by the driver.
            </Typography>
        </span>
        <span>
            <div style={{display:'flex', flexDirection:'row'}}>
                <StepIcon icon={4} sx={{color:'secondary.main'}}/>
                <Typography sx={{paddingLeft:'8px',fontSize:'0.875rem',fontWeight:600}}>Track in real-time</Typography>
            </div>
            <Typography style={{marginLeft:'12px', paddingLeft:showLogin ? '20px' : '21px', paddingBottom:'16px', borderLeft: showLogin && '1px solid #bdbdbd'}}>
                You can track the status of the parcel in real-time by clicking
                <Link
                    to={{
                        pathname: "/track",
                        search: `?parcelId=${parcelId}`,
                    }}
                    target="_blank"
                    style={{marginLeft:2}}
                >here.
                </Link>
            </Typography>
        </span>
        {showLogin && <span>
            <div style={{display:'flex', flexDirection:'row'}}>
                <StepIcon icon={5} sx={{color:'secondary.main'}}/>
                <Typography sx={{paddingLeft:'8px',fontSize:'0.875rem',fontWeight:600}}>Save this information to your account</Typography>
            </div>
            <Typography style={{marginLeft:'12px', paddingLeft:'21px', paddingBottom:'16px'}}>
                You do not appear to have an account. Creating an account takes less than a minute and if you have a Google or Facebook account, you can login with
                one click. Having an account makes it easier to track your parcel, see proof of delivery, claim refunds or even better, earn money from unused space at home or your business.

            </Typography>
            <div style={{marginLeft:'12px',paddingLeft:'9px', width:'100%', display:'flex', justifyContent:'center'}}>
                <Button
                    variant="contained"
                    color="secondary"
                    style={{marginRight:'8px'}}
                    onClick={addUserToDb}
                >
                    Yes, I am in!
                </Button>
                <Button
                    style={{color:'inherit'}}
                    onClick={noThanks}
                >
                    No thanks
                </Button>
            </div>

        </span>}
    </Box>
  );
}
