const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN ,
    databaseURL: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseio.com`,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    region:'europe-west2',
    googleBucket:`https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`
};

export default firebaseConfig;
