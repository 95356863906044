// import { useContext } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import homeBgImageTablet from '../../../images/homescreen-tablet.jpg';
import homeBgImageDesktop from '../../../images/homescreen-desktop.jpg';
import homeBgImageXL from '../../../images/homescreen-xlarge.jpg';
// import homeBgImageDesktop from '../../../images/home_image.jpg';

import { LazyLoadImage } from 'react-lazy-load-image-component';
// import CustomGoogleMapsContainer from '../CustomGoogleMapsContainer';
// import { GoogleMapViewContext } from '../../../context/GoogleMapViewContext';

import HomeForm from './HomeForm';

function HomeTop(props) {
    const theme = useTheme();
    // const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const isMd = useMediaQuery(theme.breakpoints.down('md'));
    const isLg = useMediaQuery(theme.breakpoints.down('lg'));
    // const isXL = useMediaQuery(theme.breakpoints.up('lg'));
    const showImg = useMediaQuery(theme.breakpoints.up('md'));
    const isMiniPad = !useMediaQuery('(min-height:800px)');
    // console.log({isSm, isMd, isLg,isXL,showImg, isMiniPad})
    // const { viewMap } = useContext(GoogleMapViewContext);

    if (!showImg) return (
        <section style={{ position: 'relative', width: '100%', height: 'auto' }}>
            <Container>
                <HomeForm />
            </Container> 
        </section>
    )

    return (
        <section style={{ position: 'relative', width: '100%', height: 'auto' }}>
            <Box
                sx={{
                    zIndex: 0,
                    position: 'relative',
                    height: 'auto',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <LazyLoadImage
                    alt={'Home Background'}
                    effect="blur"
                    src={isMd ? homeBgImageTablet : 
                        isLg ? homeBgImageDesktop : homeBgImageXL }
                    style={{
                        width: '100%',
                        objectFit: 'cover',
                        backgroundPosition: '50% 50%',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        position: 'relative',
                        height: '100%',
                    }}
                />
             
                {/* {isLg && viewMap && (
                    <Box sx={{ height: '400px', width: '100%' }}>
                        <CustomGoogleMapsContainer />
                    </Box>
                )} */}
 
                <Container
                    disableGutters
                    sx={{
                        display: 'grid',
                        position: 'absolute',
                        gridTemplateColumns: '50% 50%',
                        padding: `${isLg 
                                    ? isMiniPad ? '10px' : '32px' 
                                    : '64px'}`,
                        height: 'auto',
                        margin: 'auto',
                    }}
                >
                    <HomeForm />
                </Container>
              
            </Box>
        </section>
    );
}

export default HomeTop;
