import React, { useState, useContext } from 'react';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { TrackParcelContext } from "../../context/TrackParcelContext";
import { convertDateTime, titleCase } from "../../utils";
import ThemedLoadingButton from "../../component/Buttons/ThemedLoadingButton";

const ParcelStatusHeaderMessage = props => {
    const { trackingResult, cancelOrder} = useContext(TrackParcelContext);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isOrderCancelled, setIsOrderCancelled] = useState(false);

    const { downloadLabel,downloadLabelDisabled, parcelStatus, parcelStatusText, id, parcelDates, toName, toAddress, isSender, canCancel } = trackingResult.data
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    
    const onDownload = () => {
        const link = document.createElement("a");
        link.download = `label.pdf`;
        link.href = downloadLabel;
        link.click();
    };

    const handleOpen = () =>{
        setOpen(true);
        setErrorMessage('');
    }

    const handleClose = () => {
        setLoading(false);
        setOpen(false);
    };

    const cancelPayPalOrder = async () =>{
        setLoading(true);
        setErrorMessage('');
        const response = await cancelOrder(id);
        setError(response.error);
        setErrorMessage(response.message);
        setLoading(false);
        if (response.error===false) {
            setIsOrderCancelled(true)
        }
    }
    return (
        <>
        <Container maxWidth={isXs ? 'xs' : 'md'}>
            <Box 
                sx={{
                    display: 'flex',
                    flexDirection: {
                        xs: 'column',
                        sm: 'row',
                    },
                    background: 'white',
                    justifyContent: 'center',
                    borderRadius:1,
                    p:'8px',
                    mb:'16px',
                }}
            >
                <Box sx={{ flex: 4 }}>
                    <Typography variant="h5" sx={{p:1,fontWeight:600}}>
                        {parcelStatus}
                    </Typography>
                    <Typography variant="body1" sx={{paddingLeft:1,paddingRight:1,paddingBottom:1}}>
                        {parcelStatusText}
                    </Typography>
                    <Typography variant="body1" sx={{paddingLeft:1,paddingRight:1, fontWeight:600}}>
                        Tracking number
                    </Typography>
                    <Typography sx={{paddingLeft:1}}>{id}</Typography>
                    <Typography variant="body1" sx={{paddingLeft:1,paddingTop:1,paddingRight:1, fontWeight:600}}>
                        {parcelDates.label}
                    </Typography>
                    <Typography sx={{paddingLeft:1,paddingRight:1,paddingBottom:1}}>{convertDateTime(new Date(parcelDates.value._seconds * 1000))}</Typography>

                </Box>
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {isXs 
                    ? <Divider orientation="horizontal" flexItem /> 
                    :  <Divider orientation="vertical" variant="middle"/>}
                </Box>
                <Box sx={{ flex: 4 }}>
                    <Typography variant="h5" sx={{p:1,fontWeight:600}}>Delivery Address</Typography>
                    <Typography variant="body1" sx={{paddingLeft:1,paddingRight:1, fontWeight:500}}>{titleCase(toName)}</Typography>
                    <Typography variant="body1" sx={{paddingLeft:1,paddingRight:1,paddingBottom:1}}>{titleCase(toAddress)}</Typography>
                    {isSender && <>
                        <div style={{padding:theme.spacing(1)}}>
                            <Button
                                fullWidth
                                color={'secondary'}
                                variant={'contained'}
                                disabled={downloadLabelDisabled||false}
                                // href={downloadLabel}
                                // target="_blank"
                                // download={true}
                                onClick={onDownload}
                            >
                                {/* <a href={downloadLabel} target="_blank" rel="noreferrer" download> */}
                                    Download receipt
                                {/* </a> */}
                            </Button>
                        </div>
                        {canCancel && <div style={{padding:theme.spacing(1),paddingTop:0}}>
                            <Button
                                // fullWidth
                                color={'error'}
                                onClick={handleOpen}
                            >
                                Cancel order
                            </Button>
                        </div> }
                    </>}
                </Box>
                
                
            </Box>
        </Container>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Cancel order?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to cancel your order?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ThemedLoadingButton variant="contained" color="error" onClick={handleClose} autoFocus>
                    Close
                </ThemedLoadingButton>
                <ThemedLoadingButton variant="contained" color="disabled" onClick={cancelPayPalOrder} loading={loading} disabled={loading || isOrderCancelled}>
                    Cancel Order
                </ThemedLoadingButton>
            </DialogActions>
            { errorMessage!=='' && <Alert onClose={()=>setErrorMessage('')} severity={error ? 'error' : 'success'} sx={{m:'8px'}}>{errorMessage}</Alert>}
        </Dialog>
        </>
    );
}
export default ParcelStatusHeaderMessage;
