import React, { useContext } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import { PACKAGE_OPTIONS, DELIVERY_TYPE } from '../../AppConstants';
import PackageType from '../../component/PackageType';
import PackageDeliveryType from '../../component/PackageDeliveryType';
import PackagePickupTime from '../../component/PackagePickupTime';
import PackageLocation from '../../component/PackageLocation';
import { PackageContext } from '../../context/PackageContext';

function ParcelDetailsTab() {
    const {
        priceResult,
        volumeKey,
        selectedVolumeCost,
        selectedDeliveryType,
        setVolumeKey,
        setSelectedVolumeCost,
        setSelectedDeliveryType,
        pickupDate,
        setPickupDate,
        pickupTime,
        setPickupTime,
        fromAddress,
        setFromAddress,
        toAddress,
        setToAddress,
    } = useContext(PackageContext);

    const {availablePackages, prices, receivedAt} = priceResult;
    const fromAddressList = priceResult.fromAddress;
    const toAddressList = priceResult.toAddress;

    return (
        <>
            <>
                {' '}
                <Typography style={{ marginBottom: '16px' }} fontWeight={600}>How big is your parcel ?</Typography>
            </>

            <Stack
                spacing={2}
                direction={{
                    xs: 'column',
                    sm: 'column',
                    md: 'row',
                    lg: 'row',
                    xl: 'row',
                }}
                justifyContent={'space-between'}
                aria-label="parcel details"
                sx={{
                    height: '100%',
                    width: '100%',
                }}
            >
                {PACKAGE_OPTIONS.map(data => {
                    const disabled = availablePackages.indexOf(data.index) <0 ? true : false;
                    const price=disabled ? 0 : prices.volumeCharge[data.index];
                    return (
                        <PackageType
                            key={data.index}
                            volumeKey={volumeKey}
                            price={price}
                            currencySymbol={prices.currencySymbol}
                            selectedVolumeCost={selectedVolumeCost}
                            setVolumeKey={setVolumeKey}
                            setSelectedVolumeCost={setSelectedVolumeCost}
                            data={data}
                            disabled={disabled}
                        />
                    );
                })}
            </Stack>
            <Divider style={{ margin: '20px 0' }} />
            <Typography style={{ marginBottom: '8px' }} fontWeight={600}>How should we deliver it?</Typography>
            <Typography style={{ marginBottom: '16px' }}>
                The speed and care we put in each delivery is same regardless of the option you choose. We provide fast deliveries as standard but you can pay extra for additional benefits.
            </Typography>
            <Stack
                spacing={2}
                direction={{
                    xs: 'column',
                    sm: 'column',
                    md: 'row',
                    lg: 'row',
                    xl: 'row',
                }}
                justifyContent={'space-between'}
                aria-label="package delivery type"
                sx={{
                    height: '100%',
                    width: '100%',
                }}
            >
                {DELIVERY_TYPE.map(data => {
                    const price=prices.deliveryType[data.id];
                    return (
                        <PackageDeliveryType
                            key={data.id}
                            currencySymbol={prices.currencySymbol}
                            price={price}
                            deliveryType={prices.deliveryType}
                            selectedDeliveryType={selectedDeliveryType}
                            setSelectedDeliveryType={setSelectedDeliveryType}
                            data={data}
                        />
                    );
                })}

            </Stack>

            <Divider style={{ margin: '20px 0' }} />

            <Typography style={{ marginTop: '16px', marginBottom: '16px' }} fontWeight={600}>Package Pickup Preference ?</Typography>

            {!prices.dropOffAvailable && <Typography style={{ marginBottom: '16px' }}>
                We could not find any free drop off locations within 10 miles of your postcode so we are giving you a free pickup from home option.
            </Typography>}

            <PackagePickupTime
                currencySymbol={prices.currencySymbol}
                pickupTimes={prices.pickupTimes}
                pickupTimesCharge={prices.pickupTimesCharge}
                pickupDate={pickupDate}
                setPickupDate={setPickupDate}
                pickupTime={pickupTime}
                setPickupTime={setPickupTime}
                receivedAt={receivedAt}
            />

            <Divider style={{ margin: '16px 0' }} />
            <Typography style={{ marginBottom: '8px' }} fontWeight={600}>Parcel location?</Typography>
            <Typography style={{ marginBottom: '16px' }}>
                Please select the pickup and delivery address of your parcel.
            </Typography>
            <PackageLocation
                fromAddressList={fromAddressList}
                toAddressList={toAddressList}
                fromAddress={fromAddress}
                setFromAddress={setFromAddress}
                toAddress={toAddress}
                setToAddress={setToAddress}
            />
        </>
    );
}

export default ParcelDetailsTab;
